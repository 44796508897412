<template>
  <v-app>
    <div class="auth-wrapper auth-v1">
      <div class="auth-inner2 pt-6">
        <v-card class="my-12 pt-10 pb-8 px-6">
          <div class="d-flex justify-center mb-9" style="font-size: 20px; font-weight: bold">
            <span>パスワードを変更する</span>
          </div>
          <template v-if="apierror.status == 'error'">
            <div v-for="msg of apierror.messages" :key="msg">
              <v-row class="ml-6 mb-3 ma-3">
                <span style="color: red">* {{ msg }} </span>
              </v-row>
            </div>
          </template>
          <v-row>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="11" md="10">
              <v-row>
                <v-col cols="12" md="5">
                  <div>
                    <span>現在のパスワード</span>
                    <span
                      class="ml-1 pr-1"
                      style="background-color: #ff6e40; color: #fff; font-size: 13px; border-radius: 4px"
                    >
                      必須</span
                    >
                  </div>
                </v-col>
                <v-col cols="12" md="7">
                  <v-text-field
                    v-model="$v.Form.old_password.$model"
                    outlined
                    dense
                    color="#ff6e40"
                    :error-messages="passwordOldErrors"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show1 ? 'text' : 'password'"
                    autocomplete="old-password"
                    placeholder="半角英数字記号6-16文字"
                    @click:append="show1 = !show1"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="5">
                  <div>
                    <span> 新しいパスワード</span>
                    <span
                      class="ml-1 pr-1"
                      style="background-color: #ff6e40; color: #fff; font-size: 13px; border-radius: 4px"
                    >
                      必須</span
                    >
                  </div>
                </v-col>
                <v-col cols="12" md="7">
                  <v-text-field
                    v-model="$v.Form.new_password.$model"
                    outlined
                    dense
                    color="#ff6e40"
                    :error-messages="passwordNewErrors"
                    :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show2 ? 'text' : 'password'"
                    autocomplete="new-password"
                    placeholder="半角英数字記号6-16文字"
                    @click:append="show2 = !show2"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="5">
                  <div>
                    <span> 新しいパスワード(確認)</span>
                    <span
                      class="ml-1 pr-1"
                      style="background-color: #ff6e40; color: #fff; font-size: 13px; border-radius: 4px"
                    >
                      必須</span
                    >
                  </div>
                </v-col>
                <v-col cols="12" md="7">
                  <v-text-field
                    v-model="$v.Form.confirm_password.$model"
                    outlined
                    dense
                    color="#ff6e40"
                    :error-messages="confirmPasswordErrors"
                    :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show3 ? 'text' : 'password'"
                    autocomplete="check-password"
                    placeholder="半角英数字記号6-16文字"
                    @click:append="show3 = !show3"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="mt-9">
                <v-spacer></v-spacer>
                <v-col cols="6" sm="3" class="d-flex justify-end">
                  <v-btn outlined width="100%" depressed @click="back">
                    <span>取消</span>
                  </v-btn>
                </v-col>
                <v-col cols="6" sm="3" class="d-flex justify-end">
                  <v-btn color="#ff6e40" depressed width="100%" @click="submit">
                    <span style="color: #fff">変更</span>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-spacer></v-spacer>
          </v-row>
        </v-card>
      </div>
    </div>
  </v-app>
</template>

<script>
import { mapActions } from 'vuex'
import { required, sameAs, maxLength, minLength } from 'vuelidate/lib/validators'

export default {
  data: () => ({
    show1: false,
    show2: false,
    show3: false,
    Form: {
      old_password: '',
      new_password: '',
      confirm_password: '',
    },
    apierror: {
      code: '',
      status: '',
      messages: [],
    },
  }),
  validations: {
    Form: {
      old_password: {
        required,
        maxLength: maxLength(16),
        minLength: minLength(6),
      },
      new_password: {
        required,
        maxLength: maxLength(16),
        minLength: minLength(6),
      },
      confirm_password: {
        required,
        sameAs: sameAs('new_password'),
        maxLength: maxLength(16),
        minLength: minLength(6),
      },
    },
  },
  computed: {
    passwordOldErrors() {
      const errors = []
      if (!this.$v.Form.old_password.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.old_password.maxLength && errors.push('最大16文字までです！')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.old_password.minLength && errors.push('最小6文字までです！')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.old_password.required && errors.push('必須項目')
      // eslint-disable-next-line no-unused-expressions

      return errors
    },
    passwordNewErrors() {
      const errors = []
      if (!this.$v.Form.new_password.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.new_password.maxLength && errors.push('最大16文字までです！')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.new_password.minLength && errors.push('最小6文字までです！')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.new_password.required && errors.push('必須項目')
      // eslint-disable-next-line no-unused-expressions

      return errors
    },

    confirmPasswordErrors() {
      const errors = []
      if (!this.$v.Form.confirm_password.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.confirm_password.maxLength && errors.push('最大16文字までです！')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.confirm_password.minLength && errors.push('最小6文字までです！')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.confirm_password.required && errors.push('必須項目')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.confirm_password.sameAs && errors.push('上記「パスワード」と一致していません。')

      return errors
    },
  },
  methods: {
    ...mapActions('auth', ['ChangePassword']),

    submit() {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        this.ChangePassword(this.Form)
          .then(() => {
            this.back()
          })
          .catch(error => {
            this.apierror.status = 'error'
            this.apierror.code = error.response.data.code
            this.apierror.messages = error.response.data.message
          })
      }
    },
    back() {
      this.$router.push('/account')
    },
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
